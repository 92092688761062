
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DoughnutChart from '@/lib/charts/doughnutChart';
import { getConfigEnv } from '@/utils/helpers';
import axiosClient from '@/lib/rest/axiosClient';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';

@Component({
  components: {
    DoughnutChart,
  },
})
export default class GptOverviewCluster extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  rankPosition: any = null;

  chartDataUpdated = 0;

  loadingClusterOverview = false;

  hoveringKey = '';

  rankPercentile: any = 0;

  hoveringOnTable = false;

  copiedWell = '';

  wellsRanking: any = [];

  chartOptions = {
    responsive: true,
    borderRadius: 50,
    maintainAspectRatio: false,
    events: [],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 90,
    tooltips: {
      bodyFontSize: 7,
    },
  };

  currentSelectionColor = '#FFFFFF';

  get placeholder() {
    if (this.rankPosition) {
      if (this.rankPosition >= 50) {
        return `BOTTOM ${100 - this.rankPosition}%`;
      } else {
        return `TOP ${this.rankPosition}%`;
      }
    }
    return 'NO DATA';
  }

  copyLink(wellName) {
    const url = `${window.location.origin}/Tasqs/${encodeURI(
      wellName
    )}?type=producing&view=tasq`;
    navigator.clipboard.writeText(url);
    this.copiedWell = wellName;
  }

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [
      {
        name: 'Tasqs',
        labels: 'Tasqs',
        backgroundColor: ['#273e55', this.currentSelectionColor],
        hoverBackgroundColor: ['#273e55', this.currentSelectionColor],
        borderColor: ['#273e55', this.currentSelectionColor],
        hoverBorderColor: this.currentSelectionColor,
        hoverBorderWidth: 1,
        borderAlign: 'outer',
        data: [1, 1],
        borderWidth: 2,
        borderRadius: 50,
      },
    ],
  };

  async mounted() {
    this.loadingClusterOverview = true;
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    try {
      const {
        data: { result: wellsRanking },
      }: any = await axiosClient.get('/analysis/well-percentile-rank', {
        params: {
          node_name: this.tasq.wellName,
          operator: operatorDetails['operatorName'],
          level:
            this.tasq && this.tasq.level
              ? this.tasq.level.toLowerCase()
              : 'well',
        },
      });

      this.wellsRanking = wellsRanking;

      const currentWell = wellsRanking.find(
        (w) => w.node_name.toLowerCase() === this.tasq.wellName.toLowerCase()
      );
      if (currentWell && currentWell.node_name) {
        this.rankPercentile = currentWell.percentile_rank;
        const remainingRank: any = 1 - this.rankPercentile;

        console.log(this.rankPercentile);

        console.log(remainingRank * 100);
        this.rankPosition = Math.round(this.rankPercentile * 100);

        this.chartData['datasets'][0].data = [
          remainingRank,
          this.rankPercentile,
        ];

        this.updateHovering(true);
      }
    } catch (error) {
      console.log(error);
    }
    this.loadingClusterOverview = false;
  }

  updateChart() {
    if (this.rankPosition) {
      let currentColor = '#273e55';
      let remainingColor = this.currentSelectionColor;
      if (this.rankPercentile >= 50) {
        currentColor = this.currentSelectionColor;
        remainingColor = '#273e55';
      }
      this.chartData['datasets'][0].backgroundColor = [
        remainingColor,
        currentColor,
      ];
      this.chartData['datasets'][0].hoverBackgroundColor = [
        remainingColor,
        currentColor,
      ];
      this.chartData['datasets'][0].borderColor = [
        remainingColor,
        currentColor,
      ];
      this.chartDataUpdated += 1;
    }
  }

  // @Watch('hoveringOnTable')
  updateHovering(color = false) {
    // if (val) {
    //   this.currentSelectionColor = '#00FFFF';
    // } else {
    //   this.currentSelectionColor = '#FFFFFF';
    // }
    this.currentSelectionColor = '#00FFFF';
    if (color) {
      this.currentSelectionColor = '#FFFFFF';
    }
    this.updateChart();
  }

  openLink(wellName) {
    const url = `${window.location.origin}/Tasqs/${encodeURI(
      wellName
    )}?type=producing&view=tasq`;
    window.open(url, '_blank');
  }
}
